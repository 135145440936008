import { ReactTyped } from 'react-typed';
import './App.scss';

function App() {
  
  // const showButton = () => {
  //   debugger
  //   const heartElement = document.querySelector('.heart');
  //   if (heartElement) {
  //     heartElement.style.display = 'block';
  //   }
  // }

  const moveNext = () =>{
    const nextElement = document.getElementById('partOne');
  
  // Verifica si el elemento fue encontrado
  if (nextElement) {
    // Calcula la posición del elemento relativa a la ventana del navegador
    const elementPosition = nextElement.getBoundingClientRect().top;
    
    // Desplaza la ventana del navegador para que el elemento esté en la vista
    window.scrollTo({
      top: window.scrollY + elementPosition,
      behavior: 'smooth' // Opcional: agrega un efecto de desplazamiento suave
    });
  }
  }
  return (
    <div className="base">
      <div className='init'>
        <div className='sobre' onClick={moveNext}>
          <div className='heart' onClick={moveNext}/> 
          <h4>Con todo mi amor</h4>
        </div>
      </div>
      <div className="part-One" id='partOne'>
        <div className='centered'>
          <ReactTyped
          backSpeed={20}
          startWhenVisible
          fadeOut
          // cursorChar='♡'
          strings={[
            'Querida Liz... <br/><br/> No sabes las ganas y el deseo que tengo de escribirte y buscarte, me haces falta a cada momento, es un martirio estar sin ti, sin hablarte y sin saber si estás bien, si ya comiste o si llegaste bien. <br/><br/> Estos últimos días siempre me dijiste que no me preocupara o que las cosas no eran necesarias, pero, solo quería mostrarte el cariño que te tengo y que me importas muchísimo...<br/><br/>  Por favor no me olvides, no olvides que te amo. ♡',
            //        'No puedo dejar de pensar en ti.',
            //        'Cada minuto que pasa, cada hora, cada día... simplemente no sales de mi cabeza',
            //        'No se como parar mis sentimientos por ti, y la verdad es que tampoco quiero hacerlo',
            //        'Te amo como nunca y quiero todo para nosotros.'
          ]}
          typeSpeed={80}
          typedRef={function noRefCheck(){}}
          />
        </div>
      </div>
      <div className={"part-Two"}> 
      <div className='centered'>
        <ReactTyped
        backSpeed={20}
        startWhenVisible
        strings={[
          'Vi una frase que dice <em>"El trabajo en equipo hace que los sueños se cumplan".</em>',
        ]}
        typeSpeed={80}
        typedRef={function noRefCheck(){}}
        />
        </div>
      </div>
      <div className={"part-Three"}> 
        <div className='centered'>
          <ReactTyped
          backSpeed={20}
          startWhenVisible
          strings={[
            'Aún creo que podemos ser un gran equipo 😔 <br/> <br/> No me quiero rendir a ti. <br/> <br/> De verdad te quiero en mi vida, un hogar y un hijo contigo y en realidad todo lo que eso conlleve, absolutamente todo y quiero hacerlo bien… ¡casémonos 💍 !. ☹️ <br/> <br/> Mencionaste a tu familia la otra noche y la verdad es que no tendría ningún problema en ir a buscar a cada uno de ellos para intentar arreglar todo también con ellos, creo que las fiestas y reuniones siempre me ayudaron a conectar con ellos, te lo digo de todo corazón, yo lo quiero todo. <br/> <br/> En mi caso la verdad es que no tienes de que preocuparte, mis papás te quieren… mi mamá me dijo que te quería invitar a comer o tomar un café… mi papá me dijo que si aceptabas, nos vayamos a la casa de la Morelos y ellos se quedaban rentando. <br/> <br/> Ellos de verdad también te quieren y aprecian. 🥺',
          ]}
          typeSpeed={80}
          typedRef={function noRefCheck(){}}
          />
        </div>
      </div>
      <div className={"part-Four"}> 
        <div className='centered'>
          <ReactTyped
          backSpeed={20}
          startWhenVisible
          strings={[
            'I just don\'t know what to do, I mean, I know what I MUST do but I refuse. I\'m really in love with you, this love surpasses me, I just can\'t with it. I don\'t want to let you go even if you are not feeling the same to me, even if I have to, as I said, I\'m 26, we only have one life and I want to live mine by your side. Spend every day, every moment, every night with you.',
          ]}
          typeSpeed={80}
          typedRef={function noRefCheck(){}}
          />
        </div>
      </div>
    </div>
  );
}
export default App;
